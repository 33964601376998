import { nanoid } from "nanoid";
import { FlowItemGroupAuthorityTypeEnum, FlowItemTypeEnum, SaveFlowRequestFlowItem } from "./SharedTypes";
import { validateEmail } from "./validateEMail";
import { validateFullName } from "./validateFullName";

export class FlowItemGroup {
    groupNo!: number;
    items: Array<FlowItemModel>;
    constructor() {
        this.items = new Array<FlowItemModel>();
    }
}
export class FlowItemModel {
    serverId?: number;
    /**
     *
     */
    constructor() {
        this.backId = nanoid();
        this.isMenuOpen = false;
    }
    getRequest() {
        return {
            // backId: this.backId,
            fullname: this.fullname,
            eMail: this.email,
            orderNo: this.orderNo,
            color: this.color,
            serverId: this.serverId,
            flowItemType: this.flowItemType,
            groupAuthorityType: this.groupAuthorityType,
            groupNo: this.groupNo,
            userId: this.userId,
            citizenshipNo: this.citizenshipNo,
            deadline: this.deadline,
        } as SaveFlowRequestFlowItem;
    }
    isMenuOpen: boolean;
    backId!: string;
    fullnameIsOk!: boolean;
    public _fullname!: string;
    public get fullname(): string {
        return this._fullname;
    }
    public set fullname(value: string) {
        this._fullname = value;
        this.showErrorsFullname = false;
        this.fullnameIsOk = validateFullName(value);
    }
    mailIsOk!: boolean;
    public _email!: string;
    public get email(): string {
        return this._email;
    }
    public set email(value: string) {
        this._email = value;
        this.showErrorsEMail = false;
        this.mailIsOk = validateEmail(value);
    }
    public _orderNo!: number;
    public get orderNo(): number {
        return this._orderNo;
    }
    public set orderNo(value: number) {
        this._orderNo = value;
    }
    isSelf!: boolean;
    color!: string;
    groupNo!: number;
    flowItemType!: FlowItemTypeEnum;
    citizenshipNo!: string;
    hasCitizenshipNo!: boolean;
    userId!: number | null;
    groupAuthorityType!: FlowItemGroupAuthorityTypeEnum;
    deadline!: string | null;
    public _showErrorsEMail!: boolean;
    public get showErrorsEMail(): boolean {
        return this._showErrorsEMail;
    }
    public set showErrorsEMail(value: boolean) {
        this._showErrorsEMail = value;
        if (value && !this.mailIsOk) {
            this.classEMailPlaceholder = "placeholder-red-400";
            this.classEMailText = "text-red-400";
        } else {
            this.classEMailPlaceholder = "placeholder-gray-400";
            this.classEMailText = "text-black";
        }
    }
    public _showErrorsFullname!: boolean;
    public get showErrorsFullname(): boolean {
        return this._showErrorsFullname;
    }
    public set showErrorsFullname(value: boolean) {
        this._showErrorsFullname = value;
        if (value && !this.fullnameIsOk) {
            this.classFullnamePlaceholder = "placeholder-red-400";
            this.classFullnameText = "text-red-400";
        } else {
            this.classFullnamePlaceholder = "placeholder-gray-400";
            this.classFullnameText = "text-black";
        }
    }
    classEMailPlaceholder!: string;
    classEMailText!: string;
    classFullnamePlaceholder!: string;
    classFullnameText!: string;
    showSearchResults!: boolean;
}
