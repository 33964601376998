import { languageItems } from "@/i18n";
import { LanguageItem } from "@/types/LanguageItem";
import { NotificationItem } from "@/types/NotificationItem";
import { GetFlowsRequest_SearchParameters, SignatureTypeEnum, SignInUpResult } from "@/types/SharedTypes";
import { HubConnectionState, HubConnection } from "@microsoft/signalr";

interface MyState {
    notifications: Array<NotificationItem>;
    signInUpResult: SignInUpResult | null;
    edittingFlowId: number | null;
    departmentIdForCreateFlow: number | null;
    signatureTypeForCreateFlow: SignatureTypeEnum | null;
    HubState: HubConnectionState | undefined;
    HubConnection: HubConnection | undefined;
    locale: LanguageItem;
    lastSearchParameters: GetFlowsRequest_SearchParameters | null;
    apiUrl: string;
}

export const state: MyState = {
    notifications: [],
    signInUpResult: null,
    edittingFlowId: null,
    departmentIdForCreateFlow: null,
    signatureTypeForCreateFlow: null,
    HubState: undefined,
    HubConnection: undefined,
    locale: languageItems[0],
    lastSearchParameters: null,
    apiUrl: "https://api.onaylarim.com/",
};

export type State = typeof state;
