const kvkkSteps = [
  {
    type: "p",
    text: "İşbu yazı yoluyla internet sitemizi ziyaret eden sizleri, 6698 sayılı Kişisel Verilerin Korunması Kanunu (kısaca “Kanun”) gereğince bilgilendirilmekteyiz. İşbu Aydınlatma Metni, internet sitemizi kullanan ziyaretçilerimizden toplanan kişisel verilerin kaydedilmesi, işlenmesi, paylaşılması, üçüncü taraflara aktarılması, saklanması, silinmesi ve imhası süreçleri ile bunlara ilişkin ilkelerimiz hakkında ziyaretçilerimizin bilgilendirilmesi amacıyla hazırlanmıştır.",
  },
  {
    type: "h1",
    text: "Veri sorumlusu",
  },
  {
    type: "p",
    text: "Seneka Yazılım Donanım Bilişim Ticaret Taahhüt Ve San. Ltd. Şti. (kısaca “şirket”) Adres: Bilkent Cyberpark C Blok No Z50 Çankaya Ankara, Mersis No: 0761049878900010, Kanun kapsamında “Veri Sorumlusudur”.",
  },
  {
    type: "h1",
    text: "Kişisel verileriniz hangi amaçla işlenecek.",
  },
  {
    type: "p",
    text: "Kişisel verileriniz, aşağıdaki durum ve koşullarda Seneka tarafından Kanun kapsamında işlenmektedir:",
  },
  {
    type: "p",
    text: "Kişisel verileriniz;",
  },
  {
    type: "ul",
    items: [
      { text: "Onaylarım Uygulaması ve hizmetlerinin gerekliliklerinin yerine getirilebilmesi için," },
      { text: "Yürürlükteki mevzuat gereği yükümlülüklerimizi yerine getirmemiz için," },
      { text: "Şirketimizin resmi kurum ve kuruluşlar nezdindeki yükümlülüklerini yerine getirebilmesi için," },
      { text: "İşlem güvenliğinin sağlanması için" },
      { text: "Kampanya, satış ve pazarlama faaliyetlerinde kullanmak için," },
      { text: "Düzenlediğimiz organizasyonlar ve sunduğumuz hizmetlere ile ilgili bilgi iletişimi yapılması için," },
      { text: "Sosyal medyada yapılan etkinlik haberlerinde kullanılmak amacıyla," },
      { text: "Onaylarım Uygulaması ve hizmetlerinin kullanıcıları ve ziyaretçilerinin kullanım alışkanlıklarının belirlenmesi, veri analizi yapabilmek için," },
      { text: "Size sunmayı taahhüt ettiğimiz Onaylarım Uygulaması ve hizmetlerinin kalitesinin artırmak ve sizlere daha iyi hizmet sunabilmek için" },
    ],
  },
  {
    type: "h1",
    text: "Hangi kişisel verileriniz işlenecek",
  },
  {
    type: "p",
    text: "Aşağıdakilerle sınırlı olmamak ve bunlar örnekleyici olmak kaydı ile sizinle kurulacak hukuki ilişkiye veya yapılacak işleme göre aşağıdaki verileriniz işlenecektir. Sitemize kullanıcı girişi yapmış olmanız durumunda bu veriler kullanıcı adınız ile eşleştirilecek, kullanıcı girişi yapmadıysanız kullandığınız çerez ayarlarına göre değişecek verileriniz anonim olarak işlenecektir. Çerez politikamıza https://www.onaylarim.com/cerez-politikasi adresinden erişebilirsiniz.",
  },
  {
    type: "ul",
    items: [
      { text: "Üyeliğinizi oluştururken bize ilettiğiniz bilgileriniz" },
      { text: "Hizmetlerimizi kullanırken sistemlerimize yükleyeceğiniz bunlarla sınırlı olmamak ve kullandığınız hizmetin türüne göre değişecek olmakla birlikte fatura bilgisi vb. veriler" },
      { text: "Hizmetlerimizi kullanırken yapmış olduğunuz işlemlere ilişkin veriler, işlem kayıtları, işlemlerin yapılması için ilgili resmi otorite ve regülatörlerden yetki almış iş ortaklarımız ve 3. kişilerce aktarılan veriler" },
      { text: "Sadakat / Puan / avantaj uygulama ve hizmetlerimiz için entegre olduğumuz dış sistem ve servisleri kullanmanız sonucunda oluşan alışveriş, harcama vb. verileriniz" },
      { text: "Tüm iletişim kanallarımızı kullanarak bize iletmiş olduğunuz ve/veya üyelik sırasında verdiğiniz iletişim bilgileriniz, talep ve şikayetlerinize ilişkin veriler" },
      { text: "Onaylarım Uygulaması ve hizmetlerimiz ve burada bağlantısı verilen sosyal medya hesaplarımıza yüklediğiniz veya gönderdiğiniz her türlü veri, fotoğraf, paylaşım vb." },
      { text: "İnternet sitemize ulaşmak için kullandığınız tarayıcı, üzerinden geldiğiniz internet sitesi veya uygulama gibi bilgiler" },
      { text: "5651 sayılı yasa gereği tutulması zorunlu olan IP, kullanılan protokol ve iletişim kayıtları," },
      { text: "Ziyaret ettiğiniz alt sayfalar, buralarda harcana süre vb. kullanım istatistikleri" },
      { text: "Sosyal medya hesaplarımız üzerinden yaptığınız paylaşım, gönderim, değinme vb. işlemler" },
      { text: "Katıldığınız etkinliklerimizde tarafınıza bildirim yapılarak alınacak olan görüntü ve ses kayıtlarınız." },
    ],
  },
  {
    type: "h1",
    text: "Kişisel verileriniz kimlere ve hangi amaçla aktarılabilecek",
  },
  {
    type: "p",
    text: "İşlenen kişisel verileriniz yukarıdaki amaçlar ve Kanun’un belirlediği şartlara göre;",
  },
  {
    type: "ul",
    items: [
      { text: "İştiraklerimize, grup şirketlerimize, hissedarlarımıza," },
      { text: "Hizmetlerin sunulması için bağlantı sağlanacak olan kamu ve özel hukuk tüzel kişilerine," },
      { text: "Ürün ve hizmetlerimizin sunulabilmesi amacıyla sınırlı olmak üzere iş ortaklarımıza ve tedarikçilerimize," },
      { text: "Üyelik süresince farklı ek hizmetler almanız durumunda hizmeti sunmak için zaten elimizde bulunan belgelerinizi yeni ek hizmeti sunacak çözüm ortağımıza da iletmek için," },
      { text: "Ticari, mali ve hukuki danışmanlıklar ve denetimler amacıyla danışmanlara ve kamu ve özel denetim firmalarına," },
      { text: "Kampanya, tanıtım ve duyurularımızın yapılabilmesi için bu süreçlerin içinde olan taşeron ve hizmet alınan 3.taraflara," },
      { text: "Üye şikayetlerinin çözümlenmesi amacıyla destek alınan Çağrı Merkezi ve/veya Şikayet takibi hizmeti şirketlerine," },
      { text: "Yalnızca kanunlarda şart koşulduğu ve kapsamıyla sınırlı olmak üzere yetkili kamu kurum ve kuruluşlarıyla yargı mercilerine," },
      {
        text: "İnternet sitemiz veya burada bağlantı verilen sosyal medya hesaplarımız üzerinden bir hizmet almakta iseniz bu hizmete ilişkin yükümlülüklerimizin yerine getirilebilmesi amacıyla hizmeti sağlamak için işbirliği yaptığımız tedarikçi ve danışmanlarımızla,",
      },
      { text: "Onaylarım Uygulaması ve hizmetlerimiz ile bağlantılı sosyal medya hesapları ile yaptığınız iletişim için yer sağlayıcı veya sistem sağlayıcı olan platform sahibi şirketlerle," },
      { text: "Bulut hizmetler ve yabancı tedarikçiler kullanımı nedeni ile ve sadece bu amaçlarla sınırlı olarak yurt dışında yer alan sunucularda saklanması amacıyla yurt dışında bulunan tedarikçilerimiz ve çözüm ortaklarımıza," },
    ],
  },
  {
    type: "p",
    text: "açık rızanız ya da Kanun ile belirlenmiş koşullar çerçevesinde aktarılabilecektir.",
  },
  {
    type: "h1",
    text: "Kişisel veri işlemenin yöntemi ve hukuki sebebi",
  },
  {
    type: "p",
    text: "Kişisel verileriniz, Seneka veya Seneka adına veri işleyen gerçek ya da tüzel kişiler tarafından; internet sitesi, sosyal medya kanallarımız, mobil uygulamalarımız ve bu sayılanlarla sınırlı olmamak üzere sözlü, yazılı, görsel veya elektronik kanallar aracılığıyla ve açık rızanız alınmak suretiyle toplanmaktadır. İnternet sitemiz ve bağlantılı sosyal medya hesapları ile ilgili otomatik veri toplama yöntemlerimiz için https://www.onaylarim.com/cerez-politikasi adresinden çerez politikamıza ulaşabilirsiniz.",
  },
  {
    type: "p",
    text: "Kişisel verileriniz, Seneka tarafından, ticari faaliyetlerimizin sunulabilmesi ve faaliyetlerimizde sürekliliğin sağlanabilmesi, Kanunlarca öngörülen sorumluluklarımızın tam ve doğru bir şekilde yerine getirebilmesi amacıyla işlenmektedir.",
  },
  {
    type: "h1",
    text: "Kanun gereğince yasal haklarınız",
  },
  {
    type: "p",
    text: "KVKK’nın 11. maddesinde haklarınız yazılı olup, bunlar aşağıdaki gibidir: Seneka’ya yazılı olarak başvurarak, kişisel verilerinizin;",
  },
  {
    type: "ul",
    items: [
      { text: "işlenip işlenmediğini öğrenme," },
      { text: "işlenmişse buna ilişkin bilgi talep etme," },
      { text: "işlenme amacını ve amacına uygun kullanılıp kullanılmadığını öğrenme," },
      { text: "yurt içinde veya yurt dışında aktarıldığı 3. kişileri bilme," },
      { text: "eksik veya yanlış işlenmiş olması halinde bunların düzeltilmesini isteme," },
      { text: "KVKK’nın 7. maddesinde öngörülen şartlar çerçevesinde silinmesini veya yok edilmesini veya anonim hale getirilmesini isteme," },
      { text: "aktarıldığı 3. kişilere yukarıda sayılan (4) ve (5) bentleri uyarınca yapılan işlemlerin bildirilmesini isteme," },
      { text: "münhasıran otomatik sistemler ile analiz edilmesi nedeniyle aleyhinize bir sonucun ortaya çıkmasına itiraz etme," },
      { text: "kanuna aykırı olarak işlenmesi sebebiyle zarara uğramanız hâlinde zararın giderilmesini talep etme haklarınızı kullanabilirsiniz." },
    ],
  },
  {
    type: "p",
    text: "Yukarıda belirtilen haklarınızı kullanmak için kimliğinizi tespit edici gerekli bilgiler ile kullanmayı talep ettiğiniz hakkınıza yönelik açıklamalarınızı içeren talebinizi; buradan indirebileceğiniz formu doldurarak, “Kayıtlı İkametgah: Bilkent Cyberpark C Blok No Z50 Çankaya Ankara” adresine bizzat getirebilir veya noter kanalı ile gönderebilir veya formun imzalı bir suretini destek@onaylarim.com mail adresine elektronik posta adresinizden iletebilirsiniz. Talepleriniz, talebinizin niteliğine göre mümkün olan en kısa sürede ve en geç 30 gün içerisinde ücretsiz olarak sonuçlandırılır. Ancak işlemin ayrıca bir maliyeti gerektirmesi halinde, Kişisel Verileri Koruma Kurulu tarafından belirlenen tarifedeki ücret alınabilir. Talebiniz başkası adına yapılacaksa bu konuda almış olduğunuz bir vekaletnamenin ve kimliğinizi doğrular diğer belgelerin başvurunuza eklenmiş olması gerekmektedir. Başvurunuz üzerine tarafınıza kişisel veri içeren bir cevap verilmesi veya işlenen kişisel verilerle ilgili bir işlem yapılması gerekmesi durumunda, başvuru kimlik tespiti yapılmasına uygun şekilde şahsen, noter kanalıyla veya KEP ile yapılmamışsa, Seneka verilerin yanlış kişilere teslim edilmemesi veya gerçek veri sahibi dışındaki kötü niyetli kişilerce işlem yapılmaması için kimliğinizi doğrulamanız talep edebilecektir.",
  },
  {
    type: "p",
    text: "Aydınlatma metni ve ilgili mevzuat çerçevesinde kişisel verilerimin satış, pazarlama tanıtım faaliyetleri çerçevesinde işlenmesine ve bu faaliyetlerin yürütülmesi konusunda hizmet alınan tedarikçi ve iş ortakları ile paylaşılmasına rıza gösteriyorum.",
  },
];

const kvkkContent = {
  title: "KVKK",
  subTitle: "KİŞİSEL VERİLERİN KORUNMASINA İLİŞKİN AYDINLATMA METNİ",
  steps: kvkkSteps,
};


export default kvkkContent;