import { ActionTree, ActionContext } from "vuex";
import { State } from "./state";
import { Mutations } from "./mutations";
import { ActionTypes } from "./action-types";
import axios from "axios";
import { MutationTypes } from "./mutation-types";
import { NotificationItem, NotificationItemType } from "@/types/NotificationItem";
import router from "@/router";
import * as signalR from "@microsoft/signalr";
import { LanguageItem } from "@/types/LanguageItem";
import i18n from "@/i18n";

type AugmentedActionContext = {
    commit<K extends keyof Mutations>(key: K, payload: Parameters<Mutations[K]>[1]): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, State>, "commit">;

export interface Actions {
    [ActionTypes.RESET]({ commit }: AugmentedActionContext): Promise<boolean>;
    [ActionTypes.HANDLEERROR]({ commit }: AugmentedActionContext, error: Error): boolean;
    [ActionTypes.STARTHUB]({ commit }: AugmentedActionContext, tokenAndLoginSessionId: Array<string>): boolean;
    [ActionTypes.CHANGELOCALE]({ commit }: AugmentedActionContext, newLocale: LanguageItem): boolean;
}

export const actions: ActionTree<State, State> & Actions = {
    [ActionTypes.RESET]() {
        return new Promise((resolve) => {
            resolve(true);
        });
    },
    [ActionTypes.HANDLEERROR]({ commit }, error: Error) {
        if (axios.isAxiosError(error)) {
            if (error.code && error.code === "ERR_NETWORK") {
                commit(MutationTypes.ADD_NOTIFICATION, new NotificationItem(i18n.global.t("error"), i18n.global.t("actions.connection-error"), NotificationItemType.Error));
            } else if (error.response !== undefined && error.response !== null) {
                if (error.response.status === 401) {
                    commit(MutationTypes.ADD_NOTIFICATION, new NotificationItem(i18n.global.t("error"), i18n.global.t("actions.unauthorized-access"), NotificationItemType.Error));
                    router.push("/login");
                } else if (error.response.status === 500) {
                    commit(MutationTypes.ADD_NOTIFICATION, new NotificationItem(i18n.global.t("error"), i18n.global.t("actions.server-error"), NotificationItemType.Error));
                } else if (error.response.status === 501) {
                    commit(MutationTypes.ADD_NOTIFICATION, new NotificationItem(i18n.global.t("error"), i18n.global.t("actions.unknown-method"), NotificationItemType.Error));
                } else if (error.response.status === 404) {
                    commit(MutationTypes.ADD_NOTIFICATION, new NotificationItem(i18n.global.t("error"), i18n.global.t("actions.unknown-method"), NotificationItemType.Error));
                } else if (error.response.status === 0) {
                    commit(MutationTypes.ADD_NOTIFICATION, new NotificationItem(i18n.global.t("error"), i18n.global.t("actions.connection-error"), NotificationItemType.Error));
                } else {
                    commit(MutationTypes.ADD_NOTIFICATION, new NotificationItem(i18n.global.t("error"), error.response.status.toString(), NotificationItemType.Error));
                }
            } else if (error.request !== undefined && error.request !== null) {
                commit(MutationTypes.ADD_NOTIFICATION, new NotificationItem(i18n.global.t("error"), i18n.global.t("actions.connection-error"), NotificationItemType.Error));
            } else {
                if (error.message !== undefined && error.message !== null) {
                    commit(MutationTypes.ADD_NOTIFICATION, new NotificationItem(i18n.global.t("error"), error.message, NotificationItemType.Error));
                } else {
                    commit(MutationTypes.ADD_NOTIFICATION, new NotificationItem(i18n.global.t("error"), error.name, NotificationItemType.Error));
                }
            }
        } else {
            commit(MutationTypes.ADD_NOTIFICATION, new NotificationItem(i18n.global.t("error"), error.message, NotificationItemType.Error));
        }
        return true;
    },
    [ActionTypes.STARTHUB]({ commit, state }, tokenAndLoginSessionId: Array<string>) {
        const connection = new signalR.HubConnectionBuilder()
            .withUrl(state.apiUrl + "commonHub", {
                accessTokenFactory: () => {
                    return tokenAndLoginSessionId[0];
                },
            })
            .withAutomaticReconnect()
            .build();

        connection.onreconnecting((error) => {
            commit(MutationTypes.SET_HUBSTATE, connection.state);
        });

        connection.onreconnected((error) => {
            commit(MutationTypes.SET_HUBSTATE, connection.state);
        });

        connection.on("ReceiveMessage", (messageType: string, message: string) => {
            if (messageType === "USERMESSAGEERROR") {
                commit(MutationTypes.ADD_NOTIFICATION, new NotificationItem(i18n.global.t("error"), message, NotificationItemType.Error));
            } else if (messageType === "USERMESSAGEINFORMATION") {
                commit(MutationTypes.ADD_NOTIFICATION, new NotificationItem(i18n.global.t("information"), message, NotificationItemType.Info));
            }
        });

        connection
            .start()
            .then(() => {
                connection.send("Login", tokenAndLoginSessionId[1]);
                commit(MutationTypes.SET_HUBSTATE, connection.state);
            })
            .catch((err) => console.log("Hub error:", err));

        return true;
    },
    [ActionTypes.CHANGELOCALE]({ commit }, newLocale: LanguageItem) {
        commit(MutationTypes.SET_LOCALE, newLocale);
        return true;
    },
};
