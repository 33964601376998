import { nanoid } from "nanoid";

export class NotificationItem {
  id: string;
  title: string;
  text: string;
  type: NotificationItemType;

  constructor(title: string, text: string | unknown, type: NotificationItemType) {
    this.id = nanoid();
    this.title = title;
    this.text = String(text).replaceAll("\r\n","<br/>");
    this.type = type;
  }
}

export enum NotificationItemType {
  Success = 1,
  Warning,
  Error,
  Info,
}
