import { NotificationItem } from "@/types/NotificationItem";
import { GetterTree } from "vuex";
import { State } from "./state";
import { GetFlowsRequest_SearchParameters, GetFlowsResult, SignatureTypeEnum, SignInUpResult } from "@/types/SharedTypes";
import { HubConnectionState } from "@microsoft/signalr";
import { LanguageItem } from "@/types/LanguageItem";

export type Getters = {
    getNotificationItems(state: State): Array<NotificationItem>;
    getLoginData(state: State): SignInUpResult | null;
    getEdittingFlowId(state: State): number | null;
    getDepartmentIdForCreateFlow(state: State): number | null;
    getSignatureTypeForCreateFlow(state: State): SignatureTypeEnum | null;
    getHubState(state: State): HubConnectionState | undefined;
    getSessionId(state: State): string;
    getLocale(state: State): LanguageItem;
    getLastSearch(state: State): GetFlowsRequest_SearchParameters | null;
    getBatchOperation(state: State): boolean;
    getFlows(state: State): GetFlowsResult | undefined;
    getApiUrl(state: State): string;
};

export const getters: GetterTree<State, State> & Getters = {
    getNotificationItems: (state) => {
        return state.notifications;
    },
    getLoginData: (state) => {
        return state.signInUpResult;
    },
    getSessionId: (state) => {
        return state.signInUpResult?.loginSessionId ?? "";
    },
    getEdittingFlowId: (state) => {
        return state.edittingFlowId;
    },
    getDepartmentIdForCreateFlow: (state) => {
        return state.departmentIdForCreateFlow;
    },
    getSignatureTypeForCreateFlow: (state) => {
        return state.signatureTypeForCreateFlow;
    },
    getHubState: (state) => {
        return state.HubState;
    },
    getLocale: (state) => {
        return state.locale;
    },
    getLastSearch: (state) => {
        return state.lastSearchParameters;
    },
    // SignPortalde var bu nedenle shadow olarak var
    getBatchOperation: (state) => {
        return false;
    },
    // SignPortalde var bu nedenle shadow olarak var
    getFlows: (state) => {
        return undefined;
    },
    getApiUrl: (state) => {
        return state.apiUrl;
    },
};
