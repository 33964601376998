import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import HomeView from "../views/HomeView.vue";
import LoginComponent from "../components/Authorization/LoginComponent.vue";
import RegisterComponent from "../components/Authorization/RegisterComponent.vue";
import CreatePasswordComponent from "../components/Authorization/CreatePasswordComponent.vue";
import EnterPasswordComponent from "../components/Authorization/EnterPasswordComponent.vue";
import ResetPasswordComponent from "../components/Authorization/ResetPasswordComponent.vue";
import VerifyEmailComponent from "../components/Authorization/VerifyEmailComponent.vue";
import FlowsComponent from "../components/Flows/FlowsComponent.vue";
import ViewFlow from "../components/Flows/View/ViewFlow.vue";
import SettingsView from "../components/Settings/SettingsView.vue";
import DashboardView from "../components/Dashboard/DashboardView.vue";
import SettingsView2 from "../components/Settings/SettingsView2.vue";
import ReportsView from "../components/Reports/ReportsView.vue";
import TestT from "../components/Account/TestT.vue";
import TestZ from "../components/Account/TestZ.vue";

import FlowItems from "../components/Flows/Edit/FlowItems.vue";
import FlowDocuments from "../components/Flows/Edit/FlowDocuments.vue";
import TemplateDesigner from "../components/Flows/Edit/TemplateDesigner.vue";
import SummaryGeneral from "../components/Flows/Edit/SummaryGeneral.vue";
import PayForFlow from "../components/Flows/Edit/PayForFlow.vue";
import ViewOutgoingKepMessages from "../components/Kep/ViewOutgoingKepMessages.vue";
import { store } from "@/store";
import SearchFlows from "@/components/Flows/SearchFlows.vue";

const routes: Array<RouteRecordRaw> = [
    {
        path: "/",
        name: "home",
        component: HomeView,
        children: [
            {
                path: "flows",
                name: "flows",
                component: FlowsComponent,
            },
            {
                path: "view/:flowid",
                name: "view",
                component: ViewFlow,
            },
            {
                path: "settings",
                name: "settings",
                component: SettingsView,
            },
            {
                path: "dashboard",
                name: "dashboard",
                component: DashboardView,
            },
            {
                path: "settings2",
                name: "settings2",
                component: SettingsView2,
            },
            {
                path: "reports",
                name: "reports",
                component: ReportsView,
            },
            {
                path: "testt",
                name: "testt",
                component: TestT,
                meta: { guest: true },
            },
            {
                path: "testz",
                name: "testz",
                component: TestZ,
                meta: { guest: true },
            },
            {
                path: "viewOutgoingKepMessages",
                name: "viewOutgoingKepMessages",
                component: ViewOutgoingKepMessages,
            },
            {
                path: "searchFlows",
                name: "searchFlows",
                component: SearchFlows,
            },
        ],
    },
    {
        path: "/login",
        name: "login",
        component: LoginComponent,
        meta: { guest: true },
    },
    {
        path: "/register",
        name: "register",
        component: RegisterComponent,
        meta: { guest: true },
    },
    {
        path: "/resetPass",
        name: "resetPasswordComponent",
        component: ResetPasswordComponent,
        meta: { guest: true },
    },
    {
        path: "/verifyEmail",
        name: "verifyEmailComponent",
        component: VerifyEmailComponent,
        meta: { guest: true },
    },
    {
        path: "/createPassword/:email",
        name: "createPassword",
        component: CreatePasswordComponent,
        meta: { guest: true },
    },
    {
        path: "/enterPassword/:email",
        name: "enterPassword",
        component: EnterPasswordComponent,
        meta: { guest: true },
    },
    {
        path: "/editFlowItems",
        name: "editFlowItems",
        component: FlowItems,
    },
    {
        path: "/editFlowDocuments",
        name: "editFlowDocuments",
        component: FlowDocuments,
    },
    {
        path: "/editTemplateDesigner",
        name: "editTemplateDesigner",
        component: TemplateDesigner,
    },
    {
        path: "/editPayment",
        name: "editPayment",
        component: PayForFlow,
    },
    {
        path: "/editSummary",
        name: "editSummary",
        component: SummaryGeneral,
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

router.beforeEach((to, from, next) => {
    if (to.matched.some((record) => record.meta.guest)) {
        next();
    } else {
        if (store.getters.getLoginData) {
            next();
            return;
        } else {
            next("/login");
        }
    }
});

export default router;
